$(function() {
	$('body').on('change', '.contacts__regions-filter select', function() {
		let url = $(this).val();
		let $loading = $('#partners-loading');

		$loading.show();

		$.ajax({
			url: url,
			method: 'GET',
			success: function(response) {
				let tbl = $(response)
					.find('#filter-content')
					.html();
				$('#filter-content').html(tbl);

				$loading.fadeOut();
			},
			error: function(response) {
				$loading.fadeOut();
			}
		});
	});
});
