import '../styles/style.scss';

import 'slick-carousel';
import { getCart } from './controllers/_partials/cart/header.part';
import './controllers/_partials/partners/filter.part';
import './controllers/_partials/partners/regions.part';

import GG, {
	ggPopup,
	ggAutoCurrent,
	ggResponsive,
	ggFormsController,
	//ggCustomSelect,
	//ggCustomScroll,
	//ggDraggin,
	ggAmountControl,
	ggCallback
	////formatNumberWithSpaces
} from 'ggcore';

const init = {
	config: {
		log: true
	}
	//formatNumberWithSpaces
};

GG.use(
	init,
	ggPopup,
	ggAutoCurrent,
	ggResponsive,
	ggFormsController,
	//ggCustomSelect,
	//ggCustomScroll,
	//ggDraggin,
	ggAmountControl,
	ggCallback
);

window.GG = GG;

GG.responsive = true;

function requireAll(r) {
	r.keys().forEach(r);
}

// Inject templates only in development mode (for hot-reload)
if (process.env.NODE_ENV === 'development') {
	requireAll(require.context('../', true, /\.pug/));
}

import Inputmask from 'inputmask';

$(function() {
	getCart();
	InitInputMask();

	if ($('body.mainpage').length) {
		$('body.mainpage')
			.closest('html')
			.addClass('mainhtml');
	}

	$('.header__menu .menu__entry').each(function() {
		if ($(this).find('.submenu').length) {
			$(this).addClass('menu__entry-non-empty');
		}
	});

	if ($('.gallery').length) {
		$('.gallery__list').slick({
			rows: 0,
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: true,
			dots: true
		});
	}

	$('.header__toggle').on('click', function(e) {
		e.preventDefault();

		$(this)
			.closest('.header')
			.toggleClass('opened');
		$('html').toggleClass('ov-hidden');
	});

	$('body').on('click', function() {
		$('.header.opened').removeClass('opened');
		$('html').removeClass('ov-hidden');
	});

	$('.header').click(function(e) {
		e.stopPropagation();
	});

	$('.js-callback-toggle').click(function(e) {
		e.preventDefault();

		let title = $(this).data('title');
		let subtitle = $(this).data('subtitle');

		GG.togglePopup('popup-callback');
	});

	$('.reviews__entry .entry__more').click(function(e) {
		e.preventDefault();

		$(this)
			.closest('.reviews__entry')
			.toggleClass('vis');
		$(this).html($(this).text() === 'Читать полностью' ? 'Скрыть' : 'Читать полностью');
	});

	if ($('.catalog').length) {
		$('.catalog__show-more a').click(function(e) {
			e.preventDefault();

			$(this)
				.closest('.catalog__cats')
				.find('.cats__entry')
				.toggleClass('vis');
			$(this).html($(this).text() === 'Показать еще' ? 'Скрыть' : 'Показать еще');
		});
	} else {
		$('.catalog__show-more').hide();
		$('.cats__entry').show();
	}

	$('.js-banner-btn').click(function(e) {
		e.preventDefault();

		let $this = $(this);

		$('#popup-callback')
			.find('.popup__title')
			.text(
				$this
					.closest('.catalog__banner')
					.find('.banner__title')
					.text()
			);
		$('#popup-callback')
			.find('textarea')
			.closest('.form__label')
			.find('.form__caption')
			.html('Краткое описание задачи');
		GG.togglePopup('popup-callback');
	});

	if (!GG.isMobile) {
		$('.about__video-lnk').click(function(e) {
			e.preventDefault();

			$('#popup-video')
				.find('iframe')
				.attr('src', $(this).attr('href'));
			GG.togglePopup('popup-video');
		});

		GG.callback('popupClosed.video', function(element) {
			$(element)
				.find('iframe')
				.attr('src', '');
		});
	}

	if ($('.about__license').length) {
		$('.about__license-list').slick({
			slidesToShow: 5,
			dots: false,
			swipe: false,
			responsive: [
				{
					breakpoint: 1050,
					settings: {
						slidesToShow: 4,
						swipe: true
					}
				},
				{
					breakpoint: 850,
					settings: {
						slidesToShow: 3,
						swipe: true,
						dots: true
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						swipe: true,
						arrows: false,
						dots: true
					}
				},
				{
					breakpoint: 400,
					settings: {
						slidesToShow: 1,
						swipe: true,
						arrows: false,
						dots: true
					}
				}
			]
		});
	}

	if ($('.about__partners').length) {
		$('.about__partners-list').slick({
			slidesToShow: 4,
			dots: false,
			swipe: false,
			adaptiveHeight: true,
			rows: 0,
			responsive: [
				{
					breakpoint: 1050,
					settings: {
						slidesToShow: 3,
						swipe: true
					}
				},
				{
					breakpoint: 850,
					settings: {
						slidesToShow: 2,
						swipe: true,
						dots: true
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						swipe: true,
						arrows: false,
						dots: true
					}
				}
			]
		});
	}

	if ($('.history__list').length) {
		$('.history__list').slick({
			fade: true,
			arrows: true,
			dots: false,
			swipe: false
		});

		$('.history__list .photos__list').slick({
			rows: 0,
			slidesToShow: 4,
			dots: true,
			autoplay: true,
			arrows: true,
			responsive: [
				{
					breakpoint: 1700,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						arrows: false
					}
				}
			]
		});
	}

	$('.call__arr').click(function(e) {
		e.preventDefault();
		$('.call__dropdown').slideToggle();
	});

	if ($('.about__main').length) {
		$('.page-head')
			.closest('.block')
			.addClass('white');
	}

	$('.js-filter-toggle').click(function(e) {
		e.preventDefault();
		$('.catalog-filter-container').toggleClass('vis');
	});

	let callbackForm = GG.$('#popup-callback .formsController').formsController({
		validationSuccess: function() {
			let $form = $('#popup-callback .formsController');
			let action = $form.data('url');
			let data = $form.serialize();
			let $loading = $('#popup-callback .loading');

			$loading.show();

			$.ajax({
				cache: false,
				dataType: 'json',
				method: 'POST',
				url: action,
				data: data,
				success: function(response) {
					$loading.hide();
					if (response.success) {
						$form
							.hide()
							.next('.form__success')
							.fadeIn();
					} else {
						let message = response.error || validateErrors(response.validate) || 'Unknown error';
						$form
							.find('.form__error')
							.html(message)
							.show();
					}
				},
				error: function(response) {
					$loading.hide();
					$form
						.find('.form__error')
						.html('Unknown error')
						.show();
				}
			});
		}
	});

	$('#popup-callback input[type=checkbox][required], #popup-callback input[type=radio][required]').change(function() {
		if ($(this).is(':checked')) {
			$(this)
				.closest('.form__label_error')
				.removeClass('form__label_error');
			callbackForm.formsController('check');
		} else if ($(this).not(':checked')) {
			$(this)
				.closest('.form__label')
				.addClass('form__label_error');
			callbackForm.formsController('check');
		}
	});

	$('.captcha-lnk').click(function(e) {
		e.preventDefault();

		let $this = $(this);

		$('.form__label_captcha').removeClass('form__label_error');

		$this.addClass('upd');

		$.ajax({
			cache: false,
			dataType: 'json',
			method: 'POST',
			url: '/api/registration/update-captcha',
			success: function(response) {
				$this.removeClass('upd');
				$('.form__label_captcha img').attr('src', response.image);
				$('.form__label_captcha input[name="captcha_sid"]').val(response.captcha_sid);
				console.log(response, 's');
			},
			error: function(response) {
				$(this).removeClass('upd');
				console.log(response, 'err');
			}
		});
	});

	GG.$('.reg .formsController').formsController({
		validationSuccess: function() {
			let $form = $('.reg .formsController');
			let action = $form.data('url');
			let data = $form.serialize();
			let $loading = $('.reg .loading');

			$loading.show();

			$.ajax({
				cache: false,
				dataType: 'json',
				method: 'POST',
				url: action,
				data: data,
				success: function(response) {
					$loading.hide();
					if (response.user) {
						$form.hide();
						$('.reg-success').fadeIn();
					} else {
						let message =
							response.error || validateErrors(response.responseJSON.validation) || 'Unknown error';
						if (response.responseJSON.validation.captcha) {
							$('.form__label_captcha').addClass('form__label_error');
						}
						$form
							.find('.form__error')
							.html(message)
							.show();
					}
				},
				error: function(response) {
					$loading.hide();
					let message = response.error || validateErrors(response.responseJSON.validation) || 'Unknown error';
					if (response.responseJSON.validation.captcha) {
						$('.form__label_captcha').addClass('form__label_error');
					}
					$form
						.find('.form__error')
						.html(message)
						.show();
				}
			});
		}
	});

	$('#iMap').on('mouseenter', 'area:not(.hover)', function() {
		var $name = $(this).attr('name');
		var item = $(this).closest('.map');
		$(item)
			.find('.map_part.hover')
			.removeClass('hover');
		$(item)
			.find('.map_part.' + $name)
			.addClass('hover');
	});

	$('body').on('click', 'area', function(event) {
		event.preventDefault();
		var $name = $(this).attr('name');
		var item = $(this).closest('.map');
		$(item)
			.find('.map_part.active')
			.removeClass('active')
			.removeClass('hover');
		$(item)
			.find('.map_part.' + $name)
			.addClass('active');
	});

	setFixedHeader();
	$(window).scroll(function() {
		setFixedHeader();
	});

	if ($('.content-page').length) {
		$('.content-page')
			.find('iframe')
			.wrap('<div class="iframe-wrapper"></div>');
	}

	if ($('.categories').length) {
		$('.categories__entry').mouseover(function() {
			$(this)
				.closest('.categories__list')
				.find('.categories__entry')
				.removeClass('hover');
			$(this).addClass('hover');
		});
	}

	$('.js-search-toggle').click(function(e) {
		e.preventDefault();

		let $search = $('.search');

		$search.toggleClass('opened');
		$('html').toggleClass('ov-hidden');

		if ($search.is('.opened')) {
			setTimeout(function() {
				$search.find('.search__input').focus();
			}, 500);
		}
	});

	$('body').on('click', '.search', function() {
		$('.search').removeClass('opened');
		$('html').removeClass('ov-hidden');
	});

	$('.search form').on('click', function(e) {
		e.stopPropagation();
	});
});

function InitInputMask() {
	if ($('.form__input_phone').length) {
		Inputmask({ mask: '+7 (999) 999-99-99' }).mask(document.querySelectorAll('.form__input_phone'));
	}
}

function numberWithSpaces(x) {
	var parts = x.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return parts.join('.');
}

function num_word(value, words) {
	value = Math.abs(value) % 100;
	var num = value % 10;
	if (value > 10 && value < 20) return words[2];
	if (num > 1 && num < 5) return words[1];
	if (num == 1) return words[0];
	return words[2];
}

function validateErrors(response) {
	let errorsList = [];

	for (let item in response) {
		let error = response[item] + '<br/>';

		errorsList.push(error);
	}

	return errorsList;
}

function setFixedHeader() {
	if ($(window).scrollTop() > 0) {
		$('.header').addClass('fixed');
	} else {
		$('.header').removeClass('fixed');
	}
}
