$(function() {
	$('body').on('click', '.contacts__regions .regions__lnk', function(e) {
		e.preventDefault();

		let url = $(this).attr('href');
		let region = $(this).data('region');
		let $loading = $('#region-loading');

		$loading.show();

		$.ajax({
			url: '/api' + url,
			method: 'GET',
			timeout: 0,
			headers: {
				'X-Token': window.cartToken
			},
			success: function(response) {
				$('.contacts__regions-map')
					.find('.map_part.active')
					.removeClass('active')
					.removeClass('hover');
				$('.contacts__regions-map')
					.find('.map_part.' + region)
					.addClass('active');
				let $content = $(response).find('#region-content');
				$('#region-content').html($content);
				$('html, body').animate({ scrollTop: $('#partners-tbl').offset().top }, 500);
				$loading.hide();
			}
		});
	});

	$('#iMap area').each(function() {
		let $this = $(this);
		let region = $this.attr('name');
		let location = window.location.pathname;

		$(this).attr('href', location + region + '/');
	});

	if ($('.contacts__regions-map').length) {
		$('#iMap').on('mouseenter', 'area:not(.hover)', function() {
			var $name = $(this).attr('name');
			var item = $(this).closest('.contacts__regions-map');
			$(item)
				.find('.map_part.hover')
				.removeClass('hover');
			$(item)
				.find('.map_part.' + $name)
				.addClass('hover');
		});

		$('body').on('click', 'area', function(event) {
			event.preventDefault();
			var $name = $(this).attr('name');
			var item = $(this).closest('.contacts__regions-map');
			$(item)
				.find('.map_part.active')
				.removeClass('active')
				.removeClass('hover');
			$(item)
				.find('.map_part.' + $name)
				.addClass('active');
			$('.contacts__regions .regions__lnk').removeClass('regions__lnk_current');
			$('.contacts__regions .regions__lnk[data-region=' + $name + ']')
				.addClass('regions__lnk_current')
				.click();
		});
	}
});
