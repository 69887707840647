export function getCart() {
	$.ajax({
		url: '/api/cart',
		method: 'GET',
		timeout: 0,
		headers: {
			'X-Token': window.cartToken // токен
		},
		success: function(response) {
			console.log(11, response);
			if (!response.cart || response.cart.items.length === 0) {
				$('.header__cart')
					.removeClass('has-items')
					.find('span')
					.html('Корзина');
			} else {
				$('.header__cart')
					.addClass('has-items')
					.find('span')
					.html(
						response.cart.items.length +
							' ' +
							num_word(response.cart.items.length, ['товар', 'товара', 'товаров'])
					);
			}
		},
		error: function(response) {
			console.log(response, 'error');
		}
	});
}

function num_word(value, words) {
	value = Math.abs(value) % 100;
	var num = value % 10;
	if (value > 10 && value < 20) return words[2];
	if (num > 1 && num < 5) return words[1];
	if (num == 1) return words[0];
	return words[2];
}
